import React from 'react';
import { Text } from 'wix-ui-tpa';
import { AccessibilityHtmlTags } from '../../../../utils/accessibility/constants';
import { AgendaSlot as AgendaSlotType } from '../../ViewModel/agendaSlotsViewModel/agendaSlotsViewModel';
import { AgendaSlotDetailType, DataHooks } from './constants';
import { classes, st } from './AgendaSlot.st.css';
import { useSettingsParams } from '../../Hooks/useSettingsParams';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

type AgendaSlotDetailProps = {
  content?: string;
  type: AgendaSlotDetailType;
};
const AgendaSlotDetail: React.FC<AgendaSlotDetailProps> = ({ type, content }) =>
  content ? (
    <Text
      data-hook={DataHooks.AGENDA_SLOT_DETAIL}
      data-type={type}
      tagName={AccessibilityHtmlTags.Paragraph}
      className={classes.secondaryText}
    >
      {content}
    </Text>
  ) : null;

export interface AgendaSlotProps {
  slot: AgendaSlotType;
  date: string;
}

export const AgendaSlot: React.FC<AgendaSlotProps> = ({ slot, date }) => {
  const settings = useSettings();
  const settingsParams = useSettingsParams();
  const { isMobile } = useEnvironment();

  const {
    serviceName,
    formattedTime,
    id,
    spotsLeft,
    duration,
    location,
    staffMember,
    price,
  } = slot;

  return (
    <div
      className={st(classes.root, {
        isMobile,
        alignment: settings.get(settingsParams.slotAlignment),
      })}
      data-hook={`${DataHooks.ROOT}-${date}`}
    >
      <div className={classes.container}>
        <div className={classes.col1}>
          <Text
            data-hook={DataHooks.FORMATTED_TIME}
            className={classes.primaryText}
            tagName={AccessibilityHtmlTags.Paragraph}
          >
            {formattedTime}
          </Text>
        </div>

        <div className={classes.detailsContainer}>
          <div className={classes.details}>
            <div className={classes.col2}>
              <Text
                data-hook={DataHooks.SERVICE_NAME}
                tagName={AccessibilityHtmlTags.Paragraph}
                className={`${classes.serviceName} ${classes.primaryText}`}
              >
                {serviceName}
              </Text>
              <AgendaSlotDetail
                type={AgendaSlotDetailType.LOCATION}
                content={location}
              />
              <AgendaSlotDetail
                type={AgendaSlotDetailType.STAFF_MEMBER}
                content={staffMember}
              />
              <AgendaSlotDetail
                type={AgendaSlotDetailType.DURATION}
                content={duration}
              />
              <AgendaSlotDetail
                type={AgendaSlotDetailType.PRICE}
                content={price}
              />
            </div>

            <div className={classes.col3}>
              <AgendaSlotDetail
                type={AgendaSlotDetailType.SPOTS_LEFT}
                content={spotsLeft}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
